body {
  margin-top: 50px !important;
  min-height: 100%;
  /* margin: 0; */
  padding: 0;
  background-color: "#1A1D29";
  overflow-y: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  min-height: 100%;
  margin: 0;
}
